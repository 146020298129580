import React from "react";
import { Card, CardBody } from "@paljs/ui/Card";
import packageJson from "../../package.json";
import Layout from "../components/Layouts";

import Seo from "../components/SEO";

export default function Error() {
  return (
    <Layout>
      <Seo title="Info" />
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-md-6">VERSION: </div>
            <div className="col-md-6">{packageJson.version}</div>
          </div>
        </CardBody>
      </Card>
    </Layout>
  );
}
